import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginComponent } from './components/login/login.component';
import { UploadFilesComponent } from './components/upload-files/upload-files.component';
import { LogoutComponent } from './components/logout/logout.component';
import { DisplayComponent } from './components/display/display.component';

//Temporary imports
import { ChartsSankeyComponent } from './components/charts-sankey/charts-sankey.component';
import { ChartsChordsComponent } from './components/charts-chords/charts-chords.component';

import { Routes, RouterModule } from '@angular/router'; // CLI imports router

//Verificateur de droits
import {AuthGuard} from 'src/app/guards/login.guard'

const routes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full'},
    { path: 'login', component: LoginComponent},
    { path: 'logout', component: LogoutComponent},
    { path: 'upload-files', component: UploadFilesComponent, canActivate:[AuthGuard]},
    { path: 'result', component: DisplayComponent, canActivate:[AuthGuard]},
    { path: 'sankey', component: ChartsSankeyComponent},
    { path: 'chords', component: ChartsChordsComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
