import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { TokenStorageService } from 'src/app/services/token-storage.service';


const AUTH_API = 'https://stern.tech/api/auth/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

    private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private http: HttpClient, private tokenStorage: TokenStorageService) { }

    login(username: string, password: string): Observable<any> {
        return this.http.post(AUTH_API + 'signin', {
                username,
                password
        }, httpOptions);
    }

  logout(): void {
        this.tokenStorage.signOut();
    }

  register(username: string, email: string, password: string): Observable<any> {
    return this.http.post(AUTH_API + 'signup', {
      username,
      email,
      password
    }, httpOptions);
  }

    getUser(): string{
        return this.tokenStorage.getUser();
    }

    logInEvent() : void {
        console.log("LLOOOOOOOOGGGGGINNNNIINNNNNNN !!!! ");
    }

    isLoggedIn()  : Observable<boolean>{
        return this.loggedIn.asObservable();
    }
}
