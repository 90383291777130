import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FileInfos } from './types/fileInfos';

@Injectable({
  providedIn: 'root'
})
export class FileResultsService {

  private baseUrl = 'https://stern.tech/services';

  constructor(private http: HttpClient) { }

  getResults(): Observable<FileInfos[]> {
      console.log("getResults after IA Analyze");
      return this.http.get<FileInfos[]>(`${this.baseUrl}/results`);
  }

  getPdfResult(id: String): any {
      console.log(`getPDF Details for file ${id}`);
      return this.http.get( `${this.baseUrl}/results/${id}`);
  }

  getCharts(): Observable<FileInfos[]> {
       console.log("getCharts");
       return this.http.get<FileInfos[]>(`${this.baseUrl}/charts`);
  }

  getChart(id: String): Observable<String> {
         console.log("getChart for id : "+id);
         return this.http.get<String>(`${this.baseUrl}/charts/${id}`);
  }

}
