import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FormGroup, FormControl, Validators} from '@angular/forms';

//Services
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { FileResultsService } from 'src/app/services/file-results.service';

//For charts
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

//Types
import { FileInfos } from 'src/app/services/types/fileInfos';
import { FileDisplay } from 'src/app/services/types/fileDisplay';

@Component({
  selector: 'app-charts-chords',
  templateUrl: './charts-chords.component.html',
  styleUrls: ['./charts-chords.component.css']
})
export class ChartsChordsComponent implements OnInit {

    fileType: string;
    fileInfos?: Observable<FileInfos[]>;
    chartDisplays: Map<string, FileDisplay>;
    currentChart: FileDisplay;


    constructor(private resultService: FileResultsService, private tokenService: TokenStorageService) {
        this.fileType = "SOFTSKILLS";
        this.chartDisplays = new Map();
        this.currentChart = {
            fileName : "",
            url: "",
            type: this.fileType
        }
    }


    ngOnInit(): void {
          this.loadAll();
    }


    draw(): void {

        am4core.useTheme(am4themes_animated);

        let chart = am4core.create("chartdiv", am4charts.ChordDiagram);
        chart.padding(100, 0, 100, 0);

        chart.dataSource.url = this.currentChart.url;
        chart.dataSource.requestOptions.requestHeaders = [
                      { key: "Accept", value: "application/json" },
                      { key: "Content-Type", value: "application/json" },
                      { key: "Authorization", value: 'Bearer '+this.tokenService.getToken() }
                    ];
        chart.dataSource.load();


    let link = chart.links.template;
    link.colorMode = "solid";
    link.fillOpacity = 0.1;
    link.propertyFields.fill = "linkColor";

    chart.dataFields.fromName = "depuis";
    chart.dataFields.toName = "vers";
    chart.dataFields.value = "valeur";

    let nodeTemplate = chart.nodes.template;

    let label = nodeTemplate.label;
    label.relativeRotation = 90;

//FIXME : Goto https://codepen.io/team/amcharts/pen/MBGJZO pour ameliorer le design
  }

  getDisplay(): void {
          if(this.chartDisplays.has(this.fileType)){
              let display = this.chartDisplays.get(this.fileType);
              if(typeof display != undefined)
                  this.currentChart = display as FileDisplay;
          }
  }

    private loadAll(){
      this.fileInfos = this.resultService.getCharts();
      this.fileInfos.subscribe((files: FileInfos[]) =>{
                  files.forEach( (file: FileInfos) =>{
                      let temp : FileDisplay = {
                          fileName: file.name,
                          type: file.type,
                          url: file.url
                      }
                      this.chartDisplays.set(file.type, temp);
                      //pdfDisplay.
                      console.log("Url : "+ file.url);
                      console.log("Name : "+ file.name);

                  })
                  this.getDisplay();
                  this.draw();
            });



    }

    reactiveForm = new FormGroup({
        type: new FormControl('', Validators.required)
    });

    get checkValidation(){
        return this.reactiveForm.controls;
    }


    updateType(event : any) {
        this.fileType = event.target.value;
        this.getDisplay();
        this.draw();
        console.log(event.target.value);
    }

}
