import { Component, OnInit } from '@angular/core';
import { FileResultsService } from 'src/app/services/file-results.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { Observable } from 'rxjs';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { PDFSource } from 'ng2-pdf-viewer';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { FileInfos } from 'src/app/services/types/fileInfos';
import { PdfDisplay } from 'src/app/services/types/pdfDisplay';


@Component({
  selector: 'app-display',
  templateUrl: './display.component.html',
  styleUrls: ['./display.component.css']
})
export class DisplayComponent implements OnInit {

    fileInfos?:Observable<any>;


    pdfsDisplay: Map<string, PdfDisplay>;
    pdfDisplay: PdfDisplay[] = [];

    pdfObject?: any;
    tabs?: any;

    token?:any;
    pdfFileName?:any;
    pdf?: PdfDisplay;
    activeFileName?:any;

//
fileType: string;

  constructor(private resultService: FileResultsService, private tokenService: TokenStorageService) {
        this.fileType = "SOFTSKILLS";
        this.pdfsDisplay = new Map();
        this.activeFileName = 'https://stern.tech/services/results/20210713143834-EVJQWJIJ_Demo_SOFTSKILLS.pdf';
  }

  ngOnInit(): void {
      this.pdfDisplay = new Array();
      this.token = this.tokenService.getToken();
      console.log("token"+this.token);
      this.fileInfos = this.resultService.getResults();

      this.fileInfos.subscribe((files: FileInfos[]) =>{
            files.forEach( (file: FileInfos) =>{
                let temp : PdfDisplay = {
                    fileName: file.name,
                    type: file.type,
                    url: {
                        url: file.url,
                        httpHeaders: {Authorization: 'Bearer '+this.tokenService.getToken()},
                        withCredentials: true
                    }
                }

                this.pdfsDisplay.set(file.type, temp);
                this.pdfDisplay.push(temp);
                //pdfDisplay.
                console.log("Url : "+ file.url);
                console.log("Name : "+ file.name);

                //TODO Get /result/{id}

            })

      });
      this.getDisplay();

      console.log("initialize pdf value "+this.pdf);
  }

    reactiveForm = new FormGroup({
          type: new FormControl('', Validators.required)
    });

    get checkValidation(){
        return this.reactiveForm.controls;
    }

    getDisplay(): void{

        if(this.pdfsDisplay.has(this.fileType)){
            let display = this.pdfsDisplay.get(this.fileType);
            if(display!= undefined){
                this.pdf = display;
            }
        }
    }

  updateType(event : any) {
    this.fileType = event.target.value;
    this.getDisplay();
    console.log("Update cell "+event.target.value);
  }

}
