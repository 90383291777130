<nav class="navbar navbar-expand-lg navbar-dark bg-dark" >
    <a class="navbar-brand" href="#">stern.tech</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav" *ngIf="isLoggedIn$ | async as isLoggedIn" >
        <ul class="navbar-nav">
            <li class="nav-item active">
                <a class="nav-link" routerLink="/upload-files">Upload Files <span class="sr-only">(current)</span></a>
            </li>

            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Results
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a class="dropdown-item" routerLink="/result">PDF</a>
                    <a class="dropdown-item" routerLink="/sankey">Sankey</a>
                    <a class="dropdown-item" routerLink="/chords">Chords</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#">Previous Analyses (IN PROGRESS)</a>
                </div>
            </li>
        </ul>
    </div>
    <div class="navbar-collapse collapse" *ngIf="isLoggedIn$ | async as isLoggedIn">
        <ul class="navbar-nav ml-auto">
            <li class="nav-item ">
                <a class="nav-link" routerLink="/logout" >Logout</a>
            </li>
        </ul>
    </div>
</nav>