import { Component, OnInit } from '@angular/core';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { MessageResponse } from 'src/app/services/types/messageResponse'
import { FileInfos } from 'src/app/services/types/fileInfos';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.css'],
  providers: [DatePipe]
})
export class UploadFilesComponent implements OnInit {
  content?: string;
  progressInfos: any[] = [];
  message: string[] = [];

  messageAnalyze: string[] = [];

  fileInfos?: Observable<FileInfos[]>;

  canAnalyze: boolean;

  files: any[] = [];
  event:any;
  uploaded:boolean = false;

  iconList = [ // array of icon class list based on type
    { type: "pdf", icon: "pdf" },
    { type: "docx", icon: "word" },
    { type: "txt", icon: "file" }
  ];

  constructor(private uploadService: FileUploadService,private datePipe: DatePipe) {
        this.canAnalyze = false;
  }

  ngOnInit(): void {
    this.fileInfos = this.uploadService.getFiles();
  }

  selectFiles(event: any): void {
    this.message = [];
    this.progressInfos = [];
    //this.selectedFiles = event.target.files;
  }

  upload(idx: number, file: File): void {
    this.progressInfos[idx] = { value: 0, fileName: file.name };

    if (file) {
      this.uploadService.upload(file).subscribe(
        (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progressInfos[idx].value = Math.round(100 * event.loaded / event.total);
            this.uploaded=true;
          } else if (event instanceof HttpResponse) {
            const msg = 'Uploaded the file successfully: ' + file.name;
            this.message.push(msg);
            this.canAnalyze = true;
            this.fileInfos = this.uploadService.getFiles();
          }
        },
        (err: any) => {
          console.log('Upload error : '+err.getClass);
          this.progressInfos[idx].value = 0;
          const msg = 'Could not upload the file: ' + file.name;
          this.message.push(msg);
          this.fileInfos = this.uploadService.getFiles();
        });
    }
  }

  launchAnalysis(): void {
    this.uploadService.analyze().subscribe(
            (data : MessageResponse) => {
                console.log('Message response '+data.message);
                const msg = 'Documents sent to engine';
                this.messageAnalyze.push(msg);
                this.fileInfos = this.uploadService.getFiles();
                this.canAnalyze = false;
            }
    );

  }

  uploadFiles(): void {
    this.message = [];


    if (this.files.length>0) {
      for (let i = 0; i < this.files.length; i++) {
        this.upload(i, this.files[i]);
      }
    }

  }



  /**
   * on file drop handler
   */
  onFileDropped($event:any) {
    this.prepareFilesList($event);
    this.uploadFiles();
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files:any) {
    this.prepareFilesList(files);
    this.uploadFiles();
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }


  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes:any, decimals?:any) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  modifiedDate(file:any){
    return this.datePipe.transform(file.lastModifiedDate, 'yyyy-MM-dd');
  }

  getFileExtension(filename:any) { // this will give you icon class name
    let ext = filename.split(".").pop();
    let obj = this.iconList.filter(row => {
      if (row.type === ext) {
        return true;
      } else{
        return false;
      }
    });
    if (obj.length > 0) {
      let icon = obj[0].icon;
      return icon;
    } else {
      return "";
    }
  }

}
