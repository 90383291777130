import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MessageResponse } from './types/messageResponse'
import { FileInfos } from 'src/app/services/types/fileInfos';


@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  private baseUrl = 'https://stern.tech/api';

  constructor(private http: HttpClient) { }

  upload(file: File): Observable<HttpEvent<any>> {

    console.log(file.name);

    const formData: FormData = new FormData();

    formData.append('document', file);

    const req = new HttpRequest('POST', `${this.baseUrl}/acquisitions/files`, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }

  getFiles(): Observable<FileInfos[]> {
    return this.http.get<FileInfos[]>(`${this.baseUrl}/acquisitions/files`);
  }

  analyze_old(): Observable<any> {
    console.log("analyze in service");
    return this.http.get(`${this.baseUrl}/actions`);
   }

  analyze(): Observable<MessageResponse> {
       console.log("analyze in service");
       return this.http.post<MessageResponse>(`${this.baseUrl}/actions`, null);
  }


}
