import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { TabsModule, TabsetConfig } from 'ngx-bootstrap/tabs';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

//Router
import { AppRoutingModule } from './app-routing.module';

// Components
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { UploadFilesComponent } from './components/upload-files/upload-files.component';
import { DisplayComponent } from './components/display/display.component'

//Font awesomes
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

//Interceptors
import { HTTP_INTERCEPTORS, HttpEvent } from '@angular/common/http'; //Add Token on REST requests
import { AuthInterceptor } from 'src/app/helpers/auth.interceptor'; //Verify token for pages access
import {AuthGuard} from 'src/app/guards/login.guard';
import { ChartsSankeyComponent } from './components/charts-sankey/charts-sankey.component';
import { ChartsChordsComponent } from './components/charts-chords/charts-chords.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';


@NgModule({
  declarations: [
    AppComponent,
    UploadFilesComponent,
    LoginComponent,
    LogoutComponent,
    DisplayComponent,
    ChartsSankeyComponent,
    ChartsChordsComponent,
    NavigationComponent,
    DragAndDropDirective
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TabsModule,
    PdfViewerModule,
    FontAwesomeModule,
    LoggerModule.forRoot({
        serverLoggingUrl: '/api/logs',
        level: NgxLoggerLevel.TRACE,
        serverLogLevel: NgxLoggerLevel.ERROR,
        disableConsoleLogging: false
    }),
    AppRoutingModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    AuthGuard,
    TabsetConfig
   ],
  bootstrap: [AppComponent]
})
export class AppModule { }
