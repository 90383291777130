import { Component, OnInit } from '@angular/core';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { Observable , of } from 'rxjs';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

    isLoggedIn$?: Observable<boolean>;

    constructor(private tokenStorageService: TokenStorageService) { }

    ngOnInit(): void {
        this.isLoggedIn$ = of(this.tokenStorageService.getToken() != null);
        console.log("isLoggedIn : "+this.isLoggedIn$)
    }

}
