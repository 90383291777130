

<br />
<br />


<div *ngIf="messageAnalyze.length" class="alert alert-secondary my-3" role="alert">
  <ul *ngFor="let msg of messageAnalyze; let i = index">
    <li>{{ msg }}</li>
    <li><a href="/result" >voir les résultats</a></li>
  </ul>
</div>



<div class="dropzone" appDragAndDrop (fileDropped)="onFileDropped($event)">
  <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($any($event).target.files)"  />
  <img src="assets/upload.png" alt="">
  <br/>
  <h3>Drag and drop file here</h3>
  <br/>
  <h3>or</h3>
  <br/>
  <label for="fileDropRef">Browse for file</label>
</div>

<div class="files-list">
  <div class="row" id="header" *ngIf="files.length!=0">
    <div class="col-2"> </div>
    <div class="col-2">
      <h3>
        Nom
      </h3>
    </div>
    <div class="col-3">
      <h3>
        Date
      </h3>
    </div>
    <div class="col-3">
      <h3>
        Taille
      </h3>
    </div>
    <div class="col-2">
      <h3>
        Etat
      </h3>
    </div>
  </div>
  <div class="single-file" *ngFor="let file of files; let i = index">
    <div class="row info">
      <div class="col-2">
        <div class="file-icon" style="width: 50px">
          <img src="assets/{{getFileExtension(file?.name)}}.png" width="45px" alt="file">
        </div>
      </div>
      <div class="col-2">
        <h4 [ngClass]="{'name-to-upload' : uploaded == false,'name-uploaded' : uploaded == true}" >
          {{file?.name}}
        </h4>
      </div>
      <div class="col-3">
        <p [ngClass]="{'name-to-upload' : uploaded == false,'name-uploaded' : uploaded == true}">
          {{modifiedDate(file)}}
        </p>
      </div>
      <div class="col-3">
        <p [ngClass]="{'name-to-upload' : uploaded == false,'name-uploaded' : uploaded == true}">
          {{ formatBytes(file?.size) }}
        </p>
      </div>
      <div class="col-2" *ngIf="uploaded==true">
        <p class="name-uploaded">
          Pret pour l'analyse
        </p>
      </div>
      <div class="col-2" *ngIf="uploaded==false">
        <p class="name-to-upload">
          En cours d'envoie
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-1"> </div>
      <div class="col-9">
        <div class="progress" *ngIf="uploaded == false">
          <div
                  class="progress-bar progress-bar-info progress-bar-striped"
                  role="progressbar"
                  attr.aria-valuenow="{{ progressInfos[i].value }}"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  [ngStyle]="{ width: progressInfos[i].value + '%' }"
          >
            {{ progressInfos[i].value }}%
          </div>
        </div>
      </div>
      <div class="col-2"> </div>
    </div>
    <div class="mb-2">

    </div>


  </div>
</div>

<br />
<br />

<div *ngIf="canAnalyze">
  <button
          class="btn btn-success btn-sm"
          (click)="launchAnalysis()"
  >

    Analyze
  </button>
</div>

