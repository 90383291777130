

<div class="pos-f-t">

    <div class="collapse" id="navbarToggleExternalContent">
        <div class="bg-dark p-4">
            <form [formGroup]="reactiveForm" >

                <div class="form-check">
                    <input type="radio" class="form-check-input" value="EMOTIONS" name="type" formControlName="type" (change)="updateType($event)">
                    <label class="form-check-label text-white">
                        EMOTIONS
                    </label>
                </div>

                <div class="form-check">
                    <input type="radio" class="form-check-input" value="SOFTSKILLS" name="type" formControlName="type" (change)="updateType($event)">
                    <label class="form-check-label text-white">
                        SOFTSKILLS
                    </label>
                </div>

                <div class="form-check">
                    <input type="radio" class="form-check-input" value="SENTIMENTS" name="type" formControlName="type" (change)="updateType($event)">
                    <label class="form-check-label text-white">
                        SENTIMENTS
                    </label>
                </div>

                <div class="form-check">
                    <input type="radio" class="form-check-input" value="NEEDS" name="type" formControlName="type" (change)="updateType($event)">
                    <label class="form-check-label text-white">
                        NEEDS
                    </label>
                </div>

                <div class="form-check">
                    <input type="radio" class="form-check-input" value="AFFECT" name="type" formControlName="type" (change)="updateType($event)">
                    <label class="form-check-label text-white">
                        AFFECT
                    </label>
                </div>

                <div class="form-check">
                    <input type="radio" class="form-check-input" value="MOTIVATION" name="type" formControlName="type" (change)="updateType($event)">
                    <label class="form-check-label text-white">
                        MOTIVATION
                    </label>
                </div>

                <div class="form-check">
                    <input type="radio" class="form-check-input" value="SATISFACTION" name="type" formControlName="type" (change)="updateType($event)">
                    <label class="form-check-label text-white">
                        SATISFACTION
                    </label>
                </div>

            </form>
        </div>
    </div>
    <nav class="navbar navbar-dark bg-dark">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
    </nav>
</div>

<div id="chartdiv" style="background-color: white; width: 100%; height: 200%;" >

</div>
